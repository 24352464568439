import { styled } from 'goober';

export const DropdownPaneWrapper = styled('div')`
    &#ncstate-utility-dropdown-pane {
        position: fixed;
        top: ${ ({ isOpen, topOffset }) => !!isOpen ? (topOffset + 'px') : 0 };
        padding-top: ${({isOpen, topOffset}) => !isOpen ? topOffset + 'px' : 0 };
        left: 0;
        right: 0;
        bottom: 0;
        height: ${({topOffset}) => `calc(100vh - ${topOffset + 'px'})`};
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        justify-content: flex-start;
        flex-direction: column;
        background-color: #222222;
        flex-shrink: 0;
        z-index: 99990;
        opacity: ${ ({ isOpen }) => isOpen ? 1 : 0 };
        transition: transform 0.3s ease-out, opacity 0.3s ease-out;
        transform-origin: top;
        transform: ${ ({ isOpen }) => isOpen ? '' : 'translateY(-100%)'};

        .dropdown-inner-wrapper {
            display:  ${ ({ isOpen }) => isOpen ? 'flex' : 'none' };
            flex-direction: column;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            -webkit-overflow-style: none;
            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        .dropdown-inner-container {
            flex-shrink: 0;
            flex-grow: 1;

            @media( min-width: 768px ) {
                max-width: 1200px;
                margin-left: 30px;
                margin-right: 30px;
            }

        }
    }
`;

const OutputPane = styled('div')`
    display: flex;
    width: 100%;
    @media(min-width: 576px) {
        justify-content: center;
    }
`;

export const ResultsPane = styled(OutputPane)`
    &#ncstate-utility-results-pane {
        padding: 40px 15px;
        flex-shrink: 0;
        flex-grow: 1;
        background-color: ${ ({theme})=>theme.resultsPaneBg };
        color: ${ ({theme})=>theme.linkColor };
    }
`;

export const SearchPane = styled(OutputPane)`
    &#ncstate-utility-search-pane {
        padding: 80px 15px 40px;
        flex-grow: 1;
        flex-shrink: 0;
        align-items: center;
        background-color: ${ ({theme})=>theme.searchPaneBg };
        max-width: calc(10 / 12 * 100%);
        margin-left: auto;
        margin-right: auto;

        @media(min-width: 576px) {
            padding: 55px 15px;
        }
    }
`;
