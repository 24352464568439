import { h } from 'preact';
import { useContext } from 'preact/hooks';

import ArrowDecorator from '../arrow-decorator';
import {
    ResourceLinkContainer,
    ResourceLinkList,
    SectionLabel,
    ResourceLinkItem,
    ResourceLinkItemAnchor,
    ResourceLinksCTAButton
  } from './resource-links-styling';

import ThemeContext from '../../styles/color-theme-context';

import links from '../../assets/resource-links.json';

export default function ResourceLinks({ cta, ctaURL }) {
  const theme = useContext(ThemeContext);

  return (
    <ResourceLinkContainer id="resource-container">
        <SectionLabel id="dropdown-resource-label">Resources</SectionLabel>
        <ResourceLinkList id="dropdown-resource-link-list">
          { links.resources.map( ({key, url, label}) => {
              return (
                  <ResourceLinkItem key={ key }>
                      <ResourceLinkItemAnchor>
                        <a class="utility-resources-link" href={ url }>
                            <ArrowDecorator
                                content={ label }
                            />
                        </a>
                      </ResourceLinkItemAnchor>
                  </ResourceLinkItem>
              );
          }) }
        </ResourceLinkList>
        <ul>
          <ResourceLinksCTAButton id='resources-cta-button' key="resource-link-cta">
            <a class="cta-button utility-resources-link" href={ ctaURL }>
              <span class='cta-button-container'>
                <ArrowDecorator
                    content={ cta }
                />
              </span>
            </a>
          </ResourceLinksCTAButton>
        </ul>
    </ResourceLinkContainer>
  );
}